import moment from 'moment'
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { PARTNERS_GRID } from '../../../graphql'
import { useCart } from '../../../hooks'
import { Title, IntlText, InfiniteList, Slider } from '../../../components'
import { PartnerCard, FeaturedCard, PartnersLoader } from '../../../views'

import './style.css'

export function PartnersGrid(){

  const { state: { type } } = useCart()
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery(PARTNERS_GRID, {variables: {type: "ALL_OPEN", mustHavePickup: type === "CUSTOMER_PICKUP", offset: {first: 20}, closedOffset: {first: 20}}, notifyOnNetworkStatusChange: true})

  useEffect(() => {
    document.addEventListener("visibilitychange", handleAppStateChange);

    return (() => {
      document.removeEventListener("visibilitychange", handleAppStateChange)
    })
     // eslint-disable-next-line
  }, [])  

  async function handleAppStateChange(){
    try {
      const bgts = await localStorage.getItem("tomato_go_background_ts")
      localStorage.setItem('tomato_go_background_ts', JSON.stringify(Date.now()))
      if (document.visibilityState === 'visible') {
        if (bgts && !moment(Number(bgts)).isAfter(moment().subtract(5, 'minutes'))){
            await refetch()
        }
      }
    } catch (error) {
      console.log("Refetch error", error)
    }
  }    

  function fetchMoreUpdateOpened(prev, {fetchMoreResult}){
    if (!fetchMoreResult) return prev

    return Object.assign({}, prev, {
      allOpenNodes: {
        ...fetchMoreResult.allOpenNodes,
        edges: [...prev.allOpenNodes.edges, ...fetchMoreResult.allOpenNodes.edges]
      }
    })
  }

  function fetchMoreUpdateClosed(prev, {fetchMoreResult}){
    if (!fetchMoreResult) return prev

    return Object.assign({}, prev, {
      allClosedNodes: {
        ...fetchMoreResult.allClosedNodes,
        edges: [...prev.allClosedNodes.edges, ...fetchMoreResult.allClosedNodes.edges]
      }
    })
  }

  if ((loading && networkStatus !== 4 && networkStatus !== 3 && networkStatus !== 6) || error) return(
    <PartnersLoader />
  )

  const { featuredNodes, promotionNodes, newNodes, allOpenNodes, allClosedNodes } = data

  return(
    <div id="partners-grid">
      {featuredNodes?.edges.length > 0 &&
        <div className="row partner-carousel">
          <div className="row-header">
            <div className="heading no-desc">
              <Title tag="h2"><IntlText group="partners-grid" id="featured-title" /></Title>
            </div>
          </div>
          <div>
            <Slider.Slider>
              {featuredNodes?.edges.map(({node: vendor}, key) =>
                <Slider.Slide key={vendor.id}>
                  <FeaturedCard key={key} vendor={vendor} />
                </Slider.Slide>
              )}
            </Slider.Slider>            
          </div>
        </div>
      }
      {promotionNodes?.edges.length > 0 &&
        <div className="row partner-carousel">
          <div className="row-header">
            <div className="heading no-desc">
              <Title tag="h2"><IntlText group="partners-grid" id="promotion-partners-title" /></Title>
            </div>
          </div>
          <div>
              <Slider.Slider>
                {promotionNodes?.edges.map(({node: vendor}, key) =>
                  <Slider.Slide key={vendor.id}>
                    <PartnerCard vendor={vendor} />
                  </Slider.Slide>
                )}
              </Slider.Slider>            
          </div>
        </div>
      }
      {newNodes?.edges.length > 0 &&
        <div className="row partner-carousel">
          <div className="row-header">
            <div className="heading no-desc">
              <Title tag="h2"><IntlText group="partners-grid" id="new-title" /></Title>
            </div>
          </div>
          <div>
            <Slider.Slider>
              {newNodes?.edges.map(({node: vendor}, key) =>
                <Slider.Slide key={vendor.id}>
                  <PartnerCard vendor={vendor} />
                </Slider.Slide>
              )}
            </Slider.Slider>                     
          </div>
        </div>
      }
      <div className="row padding" style={{display: "flex", flexDirection: "column", gap: 40}}>
        {allOpenNodes?.edges.length > 0 &&
          <div className="row">
            <div className="row-header">
              <div className="heading no-desc">
                <Title tag="h2"><IntlText group="partners-grid" id="all-partners-title" /></Title>
              </div>
            </div>
            <InfiniteList className="partner-cards" fetchMore={() => fetchMore({variables: {offset: {first: 20, after: allOpenNodes?.pageInfo.endCursor}}, updateQuery: fetchMoreUpdateOpened})} loading={loading} disabled={networkStatus === 3} array={allOpenNodes?.edges} pageInfo={allOpenNodes?.pageInfo}>
              {({node: vendor}, key) =>
                <PartnerCard key={key} vendor={vendor} />
              }
            </InfiniteList>
          </div>
        }
        {allClosedNodes?.edges.length > 0 && !allOpenNodes?.pageInfo?.hasNextPage &&
          <div className="row">
            <div className="row-header">
              <div className="heading no-desc">
                <Title tag="h2"><IntlText group="partners-grid" id="closed-partners-title" /></Title>
                <div className="desc"><IntlText group="partners-grid" id="closed-partners-desc" /></div>
              </div>
            </div>
            <InfiniteList className="partner-cards" fetchMore={() => fetchMore({variables: {closedOffset: {first: 20, after: allClosedNodes?.pageInfo.endCursor}}, updateQuery: fetchMoreUpdateClosed})} loading={loading} disabled={networkStatus === 3} array={allClosedNodes?.edges} pageInfo={allClosedNodes?.pageInfo}>
              {({node: vendor}, key) =>
                <PartnerCard key={key} vendor={vendor} />
              }
            </InfiniteList>
          </div>
        }
      </div>
    </div>
  )

}
