import { useContext } from 'react'

import { IntlContext } from '../../contexts'

export default function IntlText({children, id, group = "default", variables}){
  let { getTranslation } = useContext(IntlContext)
  if (!id) return children || `No translation defined`
  let string = getTranslation({group, id, defaultValue: children, variables})

  return string || children || `No translation defined: ${id}`
}
